import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientFormStatus } from '../../models/ClientFormStatus';
import DateUtils from '../../utils/DateUtils';
import { ButtonType } from './form-control/Button';
import { DatePicker, DatePickerType } from './form-control/DatePicker';
import CalendarIcon from './icon/CalendarIcon';
import Tooltip from './Tooltip';

type Props = {
  clientFormStatus: ClientFormStatus;
  value: Date | null;
  disabled: boolean;
  notBefore?: Date;
  onChange: (value: Props['value']) => void;
};

const daysOfWeekKeys = [
  'common:dow.sunday.long',
  'common:dow.monday.long',
  'common:dow.tuesday.long',
  'common:dow.wednesday.long',
  'common:dow.thursday.long',
  'common:dow.friday.long',
  'common:dow.saturday.long',
] as const;

const DueDatePicker: FC<Props> = (props) => {
  const { value, onChange, disabled, clientFormStatus, notBefore } = props;

  const { t } = useTranslation(['module', 'common']);

  const [content, overdue] = useMemo(() => {
    if (!value) {
      return [<>{t('module:buttons.due-date')}</>, false];
    }

    const daysTill = DateUtils.getDaysBetween(value, DateUtils.now);
    const dateText = DateUtils.formatDate(value);

    if (clientFormStatus !== ClientFormStatus.Completed) {
      if (daysTill === 0) {
        return [<span key={0}>{t('common:date.today')}</span>, false];
      }

      if (daysTill < 0) {
        return [<span key={0}>{dateText}</span>, true];
      }

      if (daysTill <= 7) {
        return [
          <Tooltip key={0} text={dateText}>
            {(tooltip) => <span {...tooltip}>{t(daysOfWeekKeys[value.getDay()])}</span>}
          </Tooltip>,
          false,
        ];
      }
    }

    return [
      <Tooltip key={0} text={t('module:buttons.change-due-date')}>
        {(tooltip) => <span {...tooltip}>{dateText}</span>}
      </Tooltip>,
      false,
    ];
  }, [clientFormStatus, t, value]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      className="w-fit"
    >
      <DatePicker
        type={DatePickerType.BUTTON}
        buttonType={ButtonType.TERTIARY}
        buttonIcon={<CalendarIcon className="h-4 w-4" />}
        buttonClassName={`${overdue ? '!text-semantic-alert' : ''}`}
        buttonContent={content}
        date={value || new Date()}
        onChange={onChange}
        disabled={disabled}
        notBefore={notBefore}
      ></DatePicker>
    </div>
  );
};

export default DueDatePicker;
